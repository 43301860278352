





























// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import CaptionCard from '@/components/cards/CaptionCard.vue'
import DriverDashboard from '@/components/pages/dashboard/DriverDashboard.vue'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import FleetDashboard from '@/components/pages/dashboard/FleetDashboard.vue'
import MechanicDashboard from '@/components/pages/dashboard/MechanicDashboard.vue'

// STORE
// INTERFACES
// LIBRARIES

@Component({
  components: {
    CaptionCard,
    DefaultLayout,
    DriverDashboard,
    FleetDashboard,
    MechanicDashboard,
  },
})
export default class DashboardWrapper extends Mixins(SystemMixin) {}
