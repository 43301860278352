// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// INTERFACES
import {
  PageYearsResource,
  CarPageResource,
} from '../../types'

// SCHEMA METHODS
import {
  IntermarkCarIdGetRequest,
  IntermarkYearsGetRequest,
} from '../../types/schema'

@Module({
  dynamic: true,
  name: 'driver-intermark',
  store,
  namespaced: true,
})
class DriverIntermark extends VuexModule {
  // 0==================================================== FITLERS ====================================================+o>
  months: PageYearsResource[] = []

  @Mutation
  setMonths (payload: PageYearsResource[]) {
    Vue.set(this, 'months', payload)
  }

  @Action({ rawError: true })
  async getMonths (payload: number) {
    const { data } = await IntermarkYearsGetRequest(payload)

    this.setMonths(data)
  }

  // 0==================================================== WIDGETS DATA ====================================================+o>
  // 0==================================================== CAR DATA ====================================================+o>

  carInfo: CarPageResource = {
    id: 0,
    dateOfTransfer: '',
    model: '',
    brand: '',
    vin: '',
    stateNumber: '',
    region: '',
    plannedReturnDate: '',
    serviceInterval: '',
    fillingLimit: '',
    waybill: '',
    fuelCard: '',
    casco: '',
    cascoLink: '',
    osago: '',
    osagoLink: '',
  }

  @Mutation
  setCarInfo (payload: CarPageResource) {
    Vue.set(this, 'carInfo', payload)
  }

  @Action({ rawError: true })
  async getCarInfo (payload: number) {
    const { data } = await IntermarkCarIdGetRequest(payload)

    this.setCarInfo(data)
  }
}

const DriverIntermarkModule = getModule(DriverIntermark)

export default DriverIntermarkModule
