// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// INTERFACES
import {
  PageYearsResource,
} from '../../types'

// SCHEMA METHODS
import {
  IntermarkYearsGetRequest,
} from '@/store/types'

@Module({
  dynamic: true,
  name: 'mechanic-intermark',
  store,
  namespaced: true,
})
class MechanicIntermark extends VuexModule {
  // 0==================================================== FITLERS ====================================================+o>
  months: PageYearsResource[] = []

  @Mutation
  setMonths (payload: PageYearsResource[]) {
    Vue.set(this, 'months', payload)
  }

  @Action({ rawError: true })
  async getMonths (payload: number) {
    const { data } = await IntermarkYearsGetRequest(payload)

    this.setMonths(data)
  }
}

const MechanicIntermarkModule = getModule(MechanicIntermark)
export default MechanicIntermarkModule
