

























































// CORE
import { CarPageResource } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CarInfoCard extends Vue {
  @Prop({ default: () => ({}) })
  readonly card!: CarPageResource
}
