

















































// CORE
import { Component, Mixins, Watch } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import CaptionCard from '@/components/cards/CaptionCard.vue'
import CarInfoCard from '@/components/cards/CarInfoCard.vue'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'

// STORE
import DriverIntermarkModule from '@/store/modules/driver/driver-intermark'

// INTERFACES
import { CarPageResource, NameValueResource } from '@/store/types'
import DriverWidgetsModule from '@/store/modules/driver/driver-widgets'

// CONSTS
const CAR_BRANDS = [
  'audi','bmw','cadillac','chevrolet','citroen',
  'dodge','fiat','ford','geely','haval','honda',
  'hyundai','infiniti','iveco','jaguar','jeep',
  'kia','lada','landRover','lexus','mazda',
  'mercedes','mini','mitsubishi','nissan','opel',
  'peugeot','porsche','renault','skoda','ssangYong',
  'subaru','suzuki','toyota','uaz','volkswagen','volvo',
]
// LIBRARIES

// Components
import PenaltiesWidget from '@/components/widgets/PenaltiesWidget.vue'
import OrdersWidget from '@/components/widgets/OrdersWidget.vue'

@Component({
  components: {
    CaptionCard,
    CarInfoCard,
    DefaultLayout,
    FiltersPanel,
    PenaltiesWidget,
    OrdersWidget,
  },
})
export default class DriverDashboard extends Mixins(SystemMixin, NotifyMixin) {
  private get carInfo (): CarPageResource {
    return DriverIntermarkModule.carInfo
  }

  private get icon (): string {
    let result = this.carInfo.brand.replaceAll(' ', '')
    result = result.slice(0,1).toLowerCase() + result.slice(1)
    if (CAR_BRANDS.includes(result)) {
      return `$${result}`
    } else {
      return ''
    }
  }

  private get months (): NameValueResource[] {
    const months = DriverIntermarkModule.months.filter(item => item.year === this.activeYear)
    return months.length ? months[0].months : []
  }

  private get years (): (string | number)[] {
    const years = DriverIntermarkModule.months.map(item => item.year)
    return years.length ? years : []
  }

  private get widgetsSettings () : any {
    return DriverWidgetsModule.widgetsSettings.filter(item => item.isOn)
  }

  private get isAnotherСonstruction (): boolean {
    return this.widgetsSettings.length > 1 || this.widgetsSettings.length === 0
  }

  private activeMonth: string | number = ''
  private activeYear: string | number = ''
  private thisMonth: string | number = ''

  private created () {
    DriverIntermarkModule.getMonths(+this.entity)
      .then(() => {
        const lastDate = [...DriverIntermarkModule.months].pop()
        const lastMonth = [...lastDate?.months ?? []].pop()

        this.activeYear = lastDate?.year ?? ''
        this.activeMonth = lastMonth?.value ?? ''
        this.thisMonth = lastMonth?.value ?? ''
      })

    DriverIntermarkModule.getCarInfo(+this.entity)
  }

  private handleYearChange () {
    this.activeMonth = this.months[this.months.length - 1].value
  }

  @Watch('activeMonth')
  private activeMonthWatch (newValue: string) {
    if (newValue === this.thisMonth) {
      DriverWidgetsModule.fetchWidgetsActualData(+this.entity)
    } else {
      DriverWidgetsModule.fetchWidgetsForASpecificMonth({ carParkId: +this.entity, params: { pageYearId: +this.activeMonth } })
    }
  }
}
