// CORE
import Vue from 'vue'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

// STORE
import store from '@/store'

// TYPES
import { DashboardMechanicCurrentCarParkIdGetRequest, IWidgetSetting } from '@/store/types'
import {
  DashboardMechanicCarParkIdGetRequest,
  DashboardMechanicCarParkIdGetParams,
  // DashboardFleetCarParkIdGetRequest,
  DashboardMechanicResource,
  // DashboardFleetCurrentCarParkIdGetRequest,
} from '@/store/types/schema'

@Module({
  dynamic: true,
  name: 'mechanic-widgets',
  store,
  namespaced: true,
})
class MechanicWidgets extends VuexModule {
  // ------------------------------------ CONSTS ----------------------------------------------=+o>

  // Изначальное положение виджетов (полный список)
  DEFAULT_WIDGET_SETTINGS: IWidgetSetting[] = [
    {
      title: 'Парк автомобилей',
      isOn: true,
      componentName: 'CarparkWidget',
    },
    {
      title: 'Штрафы',
      isOn: true,
      componentName: 'PenaltiesWidget',
    },
    {
      title: 'Заявки',
      isOn: true,
      componentName: 'OrdersWidget',
    },
  ]

  // ------------------------------------ WIDGETS SETTINGS ----------------------------------------------=+o>

  widgetsSettings: IWidgetSetting[] = [
    {
      title: 'Парк автомобилей',
      isOn: true,
      componentName: 'CarparkWidget',
    },
    {
      title: 'Штрафы',
      isOn: true,
      componentName: 'PenaltiesWidget',
    },
    {
      title: 'Заявки',
      isOn: true,
      componentName: 'OrdersWidget',
    },
  ]

  @Mutation
  setWidgetsSettings (
    payload: { data: IWidgetSetting[], entity: string },
    storage = true,
  ) {
    Vue.set(this, 'widgetsSettings', payload.data)

    if (storage && payload.entity) {
      // чтобы не записывать при чтении из локал сторейдж
      // Сохранили в локал сторейдж с пометкой роли и сущности
      localStorage.setItem(
        `widgetsSettings-mechanic-${payload.entity}`,
        JSON.stringify(this.widgetsSettings),
      )
    }
  }

  @Action
  syncWidgetsSettings (entity: string) {
    // проверяем наличие сохранённых параметров для данной сущности
    if (localStorage.getItem(`widgetsSettings-mechanic-${entity}`)?.length) {
      const data = JSON.parse(
        localStorage.getItem(`widgetsSettings-mechanic-${entity}`) as string,
      )

      // записываем из локал сторейдж в стор
      this.setWidgetsSettings({ data, entity }, false)
    } else {
      this.setWidgetsSettings({ data: this.DEFAULT_WIDGET_SETTINGS, entity })
    }
  }

  widgets: DashboardMechanicResource | null = null

  get carPark () {
    return this.widgets?.carPark
  }

  get penalties () {
    return this.widgets?.penalties
  }

  get orders () {
    return this.widgets?.requests
  }

  @Mutation
  setWidgets (payload: DashboardMechanicResource) {
    Vue.set(this, 'widgets', payload)
  }

  @Action({ rawError: true })
  async fetchWidgetsForASpecificMonth ({ carParkId, params }: { carParkId: number, params?: DashboardMechanicCarParkIdGetParams }) {
    const { data } = await DashboardMechanicCarParkIdGetRequest(carParkId, params)

    this.setWidgets(data)
  }

  @Action({ rawError: true })
  async fetchWidgetsActualData (payload: number) {
    const { data } = await DashboardMechanicCurrentCarParkIdGetRequest(payload)

    this.setWidgets(data)
  }

  // @Action({ rawError: true })
  // async fetchWidgetsForASpecificMonth ({ carParkId, params }: { carParkId: number, params?: DashboardMechanicCarParkIdGetParams }) {
  //   const { data } = await DashboardFleetCarParkIdGetRequest(carParkId, params)
  //
  //   this.setWidgets(data)
  // }

  // @Action({ rawError: true })
  // async fetchWidgetsActualData (payload: number) {
  //   const { data } = await DashboardFleetCurrentCarParkIdGetRequest(payload)
  //
  //   this.setWidgets(data)
  // }
}

const MechanicWidgetsModule = getModule(MechanicWidgets)
export default MechanicWidgetsModule
