// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// INTERFACES
import {
  PageYearsResource,
} from '../../types'

// SCHEMA METHODS
import {
  IntermarkYearsGetRequest,
} from '../../types/schema'

@Module({
  dynamic: true,
  name: 'fleet-intermark',
  store,
  namespaced: true,
})
class FleetIntermark extends VuexModule {
  // 0==================================================== FITLERS ====================================================+o>
  months: PageYearsResource[] = []

  @Mutation
  setMonths (payload: PageYearsResource[]) {
    Vue.set(this, 'months', payload)
  }

  @Action({ rawError: true })
  async getMonths (payload: number) {
    const { data } = await IntermarkYearsGetRequest(payload)
    this.setMonths(data)
  }
}

const FleetIntermarkModule = getModule(FleetIntermark)

export default FleetIntermarkModule
