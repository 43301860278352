































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// Types
import { NameValueResource } from '@/store/types'

// STORE
import FleetWidgetsModule from '@/store/modules/fleet/widgets'
import DriverWidgetsModule from '@/store/modules/driver/driver-widgets'
import MechanicWidgetsModule from '@/store/modules/mechanic/widgets'

// COMPONENTS
import Card from '@/components/cards/Card.vue'

@Component({
  components: {
    Card,
  },
})
export default class OrdersWidget extends Mixins(SystemMixin) {
  private orderStatus: 'all' | 'worked' | 'placed' | 'done' = 'all'

  private get orders () {
    if (this.role === 'fleet') {
      return FleetWidgetsModule.orders
    }else if (this.role === 'mechanic') {
      return MechanicWidgetsModule.orders
    } else {
      return DriverWidgetsModule.orders
    }
  }

  private get orderCounts (): NameValueResource[] {
    return this.orders?.[this.orderStatus]?.filter(item => (item.value)) ?? []
  }

  private get scrollItemHeight (): number {
    return this.$vuetify.breakpoint.mdAndUp ? 48 : 36
  }

  private handleFilterClick (orderStatus: 'all' | 'worked' | 'placed' | 'done') {
    this.orderStatus = orderStatus
  }
}
