
















































// Core
import { Component, Mixins, Prop } from 'vue-property-decorator'

// LIBRARIES
import lodash from 'lodash'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// STORE
import FleetWidgetsModule from '@/store/modules/fleet/widgets'
import DriverWidgetsModule from '@/store/modules/driver/driver-widgets'
import MechanicWidgetsModule from '@/store/modules/mechanic/widgets'

// Components
import Chart from '@/components/_uikit/Chart.vue'
import Card from '@/components/cards/Card.vue'

// HELPERS
import { formatCost } from '@/utils/functions'

@Component({
  components: {
    Chart,
    Card,
  },
})
export default class PenaltiesWidget extends Mixins(SystemMixin) {
  @Prop({ default: false })
  private isHalf!: boolean

  private get penalties () {
    if (this.role === 'fleet') {
      return FleetWidgetsModule.penalties
    }else if (this.role === 'mechanic') {
      return MechanicWidgetsModule.penalties
    } else {
      return DriverWidgetsModule.penalties
    }
  }

  private get chartData () {
    return this.penalties?.counts.map(i => i.value) ?? []
  }

  private get labels () {
    return this.penalties?.counts.map(i => i.name) ?? []
  }

  private get cost () {
    return formatCost(this.penalties?.cost ?? 0)
  }

  private get discountCost () {
    return formatCost(this.penalties?.discountCost ?? 0)
  }

  private sum = lodash.sum
}
