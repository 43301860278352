

























































































// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// STORE
import BillsModule from '@/store/modules/fleet/bills'

// INTERFACES
import { InvoiceResource } from '@/store/types'

// HELPERS
import { formatCost, handlePhoneNumber } from '@/utils/functions'

// COMPONENTS
import Card from '@/components/cards/Card.vue'

@Component({
  components: {
    Card,
  },
})
export default class BillsWidget extends Mixins(SystemMixin) {
  @Prop({ default: false })
  private isActual!: boolean

  private get bills (): InvoiceResource[] {
    switch (this.activeStatus) {
    case 'available': {
      return BillsModule.bills.filter((item: InvoiceResource) => new Date(item.finishDate).getTime() > new Date().getTime())
    }
    case 'lateToPay': {
      return BillsModule.bills.filter((item: InvoiceResource) => new Date(item.finishDate).getTime() < new Date().getTime())
    }
    default: {
      return BillsModule.bills
    }
    }
  }

  private get statuses () {
    return BillsModule.statuses
  }

  private get activeStatus (): number | string | undefined {
    return BillsModule.statusId
  }

  private set activeStatus (value: number | string | undefined) {
    BillsModule.setStatusId(value)
  }

  private get scrollItemHeight (): number {
    if (this.$vuetify.breakpoint.xsOnly) {
      return 68
    } else if (this.$vuetify.breakpoint.mdOnly || this.$vuetify.breakpoint.smOnly) {
      return 92
    } else if (this.$vuetify.breakpoint.lgOnly) {
      return 104
    } else {
      return 80
    }
  }

  private get scrollContainerHeight (): number {
    if (this.$vuetify.breakpoint.xsOnly) {
      return 122
    } else if (this.$vuetify.breakpoint.mdOnly || this.$vuetify.breakpoint.smOnly) {
      return 178
    } else if (this.$vuetify.breakpoint.lgOnly) {
      return 194
    } else {
      return 146
    }
  }

  private mounted () {
    BillsModule.getBills(+this.entity)
  }

  private isOverdue (bill: InvoiceResource): boolean {
    return new Date(bill.finishDate).getTime() < new Date().getTime()
  }

  private progressToPay (bill: InvoiceResource): number {
    const overallTimeToPay = new Date(bill.finishDate).getTime() - new Date(bill.startDate).getTime()
    const timeToPay = new Date(bill.finishDate).getTime() - new Date().getTime()

    return Math.ceil(timeToPay / overallTimeToPay * 100)
  }

  private daysBetween (bill: InvoiceResource): number {
    return Math.floor((new Date(bill.finishDate).getTime() - new Date().getTime()) / (1000*60*60*24))
  }

  private daysAfter (bill: InvoiceResource): number {
    return Math.floor((new Date().getTime() - new Date(bill.finishDate).getTime() ) / (1000*60*60*24))
  }

  private formatCost = formatCost
  private handlePhoneNumber = handlePhoneNumber

  private handleFilterClick (status: string) {
    this.activeStatus = status
  }
}
