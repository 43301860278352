































// CORE
import { Component, Mixins, Watch } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import CaptionCard from '@/components/cards/CaptionCard.vue'
import CarInfoCard from '@/components/cards/CarInfoCard.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'
import OrdersWidget from '@/components/widgets/OrdersWidget.vue'
import BillsWidget from '@/components/widgets/BillsWidget.vue'
import PenaltiesWidget from '@/components/widgets/PenaltiesWidget.vue'
import CarparkWidget from '@/components/widgets/CarparkWidget.vue'

// STORE
import FleetIntermarkModule from '@/store/modules/fleet/fleet-intermark'

// INTERFACES
import { IWidgetSetting, NameValueResource } from '@/store/types'
import FleetWidgetsModule from '@/store/modules/fleet/widgets'

// LIBRARIES

@Component({
  components: {
    CaptionCard,
    CarInfoCard,
    FiltersPanel,
    OrdersWidget,
    BillsWidget,
    PenaltiesWidget,
    CarparkWidget,
  },
})
export default class FleetDashboard extends Mixins(SystemMixin, NotifyMixin) {
  private get months (): NameValueResource[] {
    const months = FleetIntermarkModule.months.filter(item => item.year === this.activeYear)
    return months.length ? months[0].months : []
  }

  private get years (): (string | number)[] {
    const years = FleetIntermarkModule.months.map(item => item.year)
    return years.length ? years : []
  }

  private get widgetsSettings () : IWidgetSetting[] {
    return FleetWidgetsModule.widgetsSettings.filter(item => item.isOn)
  }

  private get isHalf (): boolean {
    const index = this.widgetsSettings?.findIndex(this.checkSatisfies.bind(this))

    if (index >= 0) {
      const next = this.widgetsSettings[index + 1]

      return this.checkSatisfies(next)
    }

    return false
  }

  private activeMonth: string | number = ''
  private thisMonth: string | number = ''
  private activeYear: string | number = ''

  private created () {
    FleetWidgetsModule.syncWidgetsSettings(this.entity)

    FleetIntermarkModule.getMonths(+this.entity)
      .then(() => {
        const lastDate = [...FleetIntermarkModule.months].pop()
        const lastMonth = [...lastDate?.months ?? []].pop()

        this.activeYear = lastDate?.year ?? ''
        this.activeMonth = lastMonth?.value ?? ''
        this.thisMonth = lastMonth?.value ?? ''
      })
  }

  private checkSatisfies (item: IWidgetSetting): boolean {
    if (item) {
      return (
        (item.componentName === 'CarparkWidget'
        || item.componentName === 'PenaltiesWidget')
        && item.isOn
      )
    }

    return false
  }

  private handleYearChange () {
    this.activeMonth = this.months[this.months.length - 1].value
  }

  @Watch('activeMonth')
  private activeMonthWatch (newValue: string) {
    if (newValue === this.thisMonth) {
      FleetWidgetsModule.fetchWidgetsActualData(+this.entity)
    } else {
      FleetWidgetsModule.fetchWidgetsForASpecificMonth({ carParkId: +this.entity, params: { pageYearId: +this.activeMonth } })
    }
  }
}
